<script setup>
import { ref } from 'vue';
import { formatValue } from '@/helpers.js';

const props = defineProps({
    packages: {
        type: Object,
        default: () => ({}),
    },
    lang: {
        type: String,
    },
    currency: {
        type: String,
    },
    savings: {
        type: String,
    },
    trialDays: {
        type: [String, Number],
        default: 0,
    },
    urlOverride: {
        type: String,
    },
});

const intervals = ref(Object.keys(props.packages));
const currentInterval = ref(intervals.value[0]);
</script>

<template>
    <article class="container mx-auto px-4 sm:px-8 flex flex-col gap-8">
        <section class="self-center flex flex-col items-end gap-2">
            <div class="join">
                <button
                    v-for="interval in intervals"
                    :key="interval"
                    class="join-item btn h-auto min-h-0 rounded-lg p-0"
                    @click="currentInterval = interval"
                >
                    <div
                        class="rounded-[0.4rem] m-[0.125rem] px-4 py-2 font-normal capitalize"
                        :class="{ 'bg-white': currentInterval === interval }"
                    >
                        {{ $t(`subscription.interval.${interval}`) }}
                    </div>
                </button>
            </div>
            <div
                class="text-primary transition-opacity"
                :style="{ 'opacity': currentInterval === 'year' ? 1 : 0 }"
            >
                {{ savings }}
            </div>
        </section>

        <section
            v-show="currentInterval === interval"
            v-for="prices, interval in packages"
            :key="interval"
            class="max-lg:self-center flex max-lg:flex-col gap-8"
        >
            <article
                v-for="price, index in prices"
                :key="index"
                class="flex-1 rounded-lg shadow-xl bg-white p-4 sm:p-8"
                :class="{
                    '-m-4 !p-8 sm:!p-12 border-2 border-primary': index == Math.floor(prices.length / 2),
                }"
            >
                <div class="text-center">
                    <h3 class="text-xl mb-4">
                        {{ price.expanded_product.name }}
                    </h3>
                    <data
                        value="$price->unit_amount"
                        class="font-bold text-5xl"
                    >
                        {{ formatValue(
                            price.unit_amount / (
                                price.recurring?.interval === 'year'
                                    ? 100 * 12
                                    : 100
                            ),
                            {
                                minimumFractionDigits: 0,
                                style: 'currency',
                                currency
                            },
                            lang,
                        ) }}
                    </data>
                    <div>
                        {{ $t('subscription.per_month') }}
                    </div>
                    <hr class="my-4 sm:my-8">
                    <ul class="flex flex-col gap-2">
                        <li
                            v-for="feature, key in price.expanded_product?.marketing_features"
                            :key="key"
                            class="flex items-center gap-2"
                        >
                            <div class="shrink-0 rounded-full bg-base-100 h-4 w-4 icon-checkmark"></div>
                            {{ feature['name'] }}
                        </li>
                    </ul>
                    <hr class="invisible my-2 sm:my-4">
                    <a
                        :href="
                            urlOverride ??
                            ('/subscription/create/' + price.id)
                        "
                        class="btn btn-primary"
                    >
                        {{ $t('landing_page.register_trial_days', trialDays) }}
                    </a>
                </div>
            </article>
        </section>
    </article>
</template>
